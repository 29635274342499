<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="区划名称">
            <a-input
              v-decorator="[ 'name', { rules: [
                    { type: 'string', required: true, message: '行政区划名称不能为空' },
                    { type: 'string', max: 100, message: '行政区划名称不能超过100个字符' },
                  ], trigger: 'change', }, ]" placeholder="请输入行政区划名称"/>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上级行政区划">
            <a-select allowClear showSearch
                      optionFilterProp="children"
                      placeholder="请选择上级行政区划"
                      v-decorator="['parentId']"
                      style="width: 100%;">
              <a-select-option :key="item.key" :value="item.key" :title="item.value" v-for="item in options">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <!--<a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24" v-if="ifUnit">
          <a-form-item label="所属单位">
            <a-select showSearch allowClear
                      optionFilterProp="children"
                      placeholder="请选择所属单位"
                      v-decorator="[ 'orgId', { rules: [{ required: true, message: '所属单位为必填项' }],
                  validateTrigger: 'change', initialValue: undefined, }, ]" style="width: 100%;">
              <a-select-option :key="item.key" :value="item.key" :title="item.value" v-for="item in onlyUnit">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>-->
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="行政区划编号：">
            <a-input v-decorator="['code',{ rules: [
                    { type: 'string', required: true, message: '行政区划编号不能为空' },
                  ], trigger: 'change', },]" placeholder="行政区划编号"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="6" :sm="24">
          <a-form-item label="排序号（显示顺序，asc排列）">
            <a-input-number :step="0.1" v-decorator="[ 'sorter', { rules: [{ type: 'number', required: true, message: '排序号必须为数字' }],
                  trigger: 'change', initialValue: 0, }, ]" placeholder="输入排序号"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :span="12">
          <a-form-item label="经度">
            <a-input v-decorator="['longitude']"  placeholder="请输入经度">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="纬度">
            <a-input v-decorator="['latitude']"   placeholder="请输入经度">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所有父节点ID信息" v-if="type === 'edit'">
            {{ formItem.parentIds }}({{ formItem.id }})
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="最近更新时间" v-if="type === 'edit'">
            {{ formItem.lastUpdated }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建时间" v-if="type === 'edit'">
            {{ formItem.dateCreated }}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建人（用户Username）" v-if="type === 'edit'">
            {{ formItem.creater }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="描述信息">
        <a-textarea v-decorator="['description']" rows="3" :maxLength="200" placeholder="请输入描述信息"></a-textarea>
      </a-form-item>
      <a-form-item v-if="type === 'edit'">
        <a-affix :offsetBottom="10">
          <a-button type="primary" style="float: right;" :loading="ifSub" @click="submitEdit">保存修改</a-button>
        </a-affix>
      </a-form-item>

    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { areaVO } from './common/areaVO'

  export default {
    name: 'AreaForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      },
      defaultParentId: {
        type: Number,
        default: undefined
      },
      defaultUnitId: {
        type: Number,
        default: undefined
      }
    },
    computed: {
      onlyUnit () {
        const temp = []
        if (this.options) {
          this.options.forEach((x) => {
            if (x.attribute === 'unit') {
              temp.push(x)
            }
          })
        }
        return temp
      }
    },
    watch: {
      defaultParentId (val) {
        if (this.type === 'add') {
          if (val) {
            this.form.setFieldsValue({ parentId: val })
          } else {
            this.form.setFieldsValue({ parentId: undefined })
          }
        }
      },
      defaultUnitId (val) {
        if (this.type === 'add') {
          if (val) {
            console.log(val)
            this.form.setFieldsValue({ unitId: val })
          } else {
            this.form.setFieldsValue({ unitId: undefined })
          }
        }
      }
    },
    data () {
      return {
        ifSchool: false,
        ifUnit: true,
        options: [],
        formItem: areaVO(),
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false
      }
    },
    methods: {
      /*
       * 通过ID获取数据
       */
      loadData (id) {
        this.formItem = areaVO()
        this.form.resetFields()
        const self = this
        this.$http(self, {
          url: SERVICE_URLS.csgz.areaDictionaryApi.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body.select
            //一定要注意 不然会导致双向绑定选择框无法选中值
            this.$nextTick(() => {
              this.setFields(data.body.content)
            })
          }
        })
      },
      setFields (values) {
        // console.log(values)
        Object.assign(this.formItem, values)
        this.formItem.parentId = values.parentId
        this.form.setFieldsValue({
          name: this.formItem.name,
          code: this.formItem.code,
          description: this.formItem.description,
          // unitId: this.formItem.unitId,
          parentId: this.formItem.parentId,
          sorter: this.formItem.sorter,
          longitude: this.formItem.longitude,
          latitude: this.formItem.latitude
        })
      },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.code = values.code
        this.formItem.description = values.description
        // this.formItem.unitId = values.unitId
        this.formItem.parentId = values.parentId
        this.formItem.sorter = values.sorter
        this.formItem.longitude = values.longitude
        this.formItem.latitude = values.latitude
      },
      /**
       * 加载下拉框数据，添加时使用
       * @param parentId 默认上级部门选中
       * @param unitId 默认单位选中
       */
      loadSelect (parentId, unitId) {
        console.log(unitId)
        this.$http(this, {
          url: SERVICE_URLS.csgz.areaDictionaryApi.select,
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body
            this.$nextTick(() => {
              this.form.setFieldsValue({
                parentId: parentId
                // unitId: unitId
              })
            })
          }
        })
      },
      submitEdit () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.csgz.areaDictionaryApi.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              success: (data) => {
                this.setFields(data.body)
                this.$emit('editSuccess', data.body)
              }
            })
          } else {
            console.info(err)
          }
        })
      },
      submitAdd () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.csgz.areaDictionaryApi.create,
              data: this.formItem,
              success: (data) => {
                //添加成功，清空表单数据，并触发回调
                this.$emit('addSuccess', data.body)
                //清空表单数据
                this.formItem = areaVO()
                this.form.resetFields()
              },
              error: () => {
                this.$emit('addError')
              }
            })
          }
        })
      }
    }
  }
</script>
